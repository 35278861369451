// My3DText.js
import React from "react";
import { Text3D } from "@react-three/drei";
import { motion } from "framer-motion-3d";

export function TextShape({ text = "AI", ...props }) {
  return (
    <motion.group {...props}>
      {/* Text3D generates extruded 3D geometry that can catch lights/shadows */}
      <Text3D
        font="/assets/fonts/helvetiker_regular.typeface.json"
        size={0.5}    // overall size of the text
        height={0.2}  // thickness/extrusion
      >
        {text}
        {/* Use any standard material so it responds to lighting */}
        <meshStandardMaterial color="#ffffff" metalness={0.2} roughness={0.4} />
      </Text3D>
    </motion.group>
  );
}
